import styled from 'styled-components';
import React from 'react';

const Section = (props) => {
  const {children, title, subtitle, contentMaxWidth = 1200} = props;
  return (
    <StyledSection>
      <div
        className="content"
        style={{
          maxWidth: contentMaxWidth,
        }}>
        <div className="caption">
          <h1>{title}</h1>
          <small>{subtitle}</small>
        </div>
        {children}
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  width: 100%;
  background-color: var(--snow-owl-theme);
  padding: 50px 20px;
  & > .content {
    display: flex;
    flex-direction: column;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    & > .caption {
      text-align: center;
      align-self: center;
      margin-bottom: 40px;
      & > h1 {
        color: var(--snow-owl-theme-font-color);
        font-size: 28px;
        line-height: 2rem;
      }
      & > small {
        color: var(--snow-owl-theme-font-color);
        font-size: 14px;
        line-height: 2rem;
      }
    }
  }
`;

export default Section;
