import React, {useState} from 'react';
import styled from 'styled-components';
import Section from '../../Components/SnowOwl/Section';
import * as AppActions from '../../AppActions';
import withPageEntry from '../../withPageEntry';
import useArtists from '../../Hooks/useArtists';
import ImagePlaceholder from '../../Components/SnowOwl/ImagePlaceholder';

const CollectionListPage = (props) => {
  const {artists, status, STATUS_MAP} = useArtists();
  return (
    <StyledCollectionListPage>
      <Section title="Explore">
        <div className="collection-list">
          {artists.map((artist, i) => {
            const {collection} = artist;
            return (
              <CollectionItem
                onClick={async () => {
                  await AppActions.navigate(
                    `/products?cat=${artist.collection.name}`,
                  );
                }}
                key={i}
                collection={collection}
              />
            );
          })}
          {new Array(3).fill('collection').map((filler, index) => (
            <div className="filler" key={index} />
          ))}
        </div>
      </Section>
    </StyledCollectionListPage>
  );
};

const StyledCollectionListPage = styled.div`
  background-color: var(--snow-owl-theme);
  padding-top: var(--topNavBarHeight);
  min-height: calc(100vh - var(--bottomSiteFooterHeight));

  & .collection-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > .filler {
      height: 1px;
      width: calc((1200px / 3) - (2 * 5px));
      margin: 5px;
    }
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
`;

const CollectionItem = (props) => {
  const {collection, onClick} = props;
  const [loaded, setLoaded] = useState(false);
  return (
    <StyledCollectionItem onClick={onClick}>
      <div className="collection-img">
        {!loaded && <ImagePlaceholder />}
        <img
          src={collection.thumbnail && collection.thumbnail[0].expected_url}
          alt="collection"
          loading="lazy"
          style={{visibility: loaded ? 'visible' : 'hidden'}}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </div>
      <div className="info">
        <div className="title">{collection.name}</div>
        <div className="subtitle">{collection.description}</div>
      </div>
    </StyledCollectionItem>
  );
};

const StyledCollectionItem = styled.div`
  width: calc((1200px / 3) - (2 * 5px));
  border-radius: 10px;
  border: 1px solid var(--snow-owl-theme-button-border-color);
  :hover {
    box-shadow: 0 5px 20px 1px #888;
  }
  cursor: pointer;
  margin: 5px 5px 50px 5px;

  & > .collection-img {
    position: relative;
    width: 100%;
    aspect-ratio: calc(400 / 200);
    & > img {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & > .info {
    padding: 5px 20px 12px 20px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    & > .title {
      font-size: 14px;
      color: var(--snow-owl-theme-font-color);
      letter-spacing: 0.5px;
      line-height: 20px;
      font-weight: 700;
    }
    & > .subtitle {
      font-size: 14px;
      color: var(--snow-owl-theme-font-color);
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export default withPageEntry(CollectionListPage);
