import {useCallback, useEffect, useState} from 'react';
import * as AppActions from '../AppActions';

const STATUS_MAP = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  failed: 'failed',
};

const useArtists = () => {
  const [artists, setArtists] = useState([]);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState(STATUS_MAP.idle);

  const fetchArtists = useCallback(async () => {
    try {
      setStatus(STATUS_MAP.loading);
      AppActions.setLoading(true);
      const resp = await AppActions.clientJStorageFetch('artist', {});
      const {results, total} = resp;
      setArtists(results);
      setTotal(total);
      setStatus(STATUS_MAP.success);
    } catch (e) {
      setArtists([]);
      setTotal(0);
      setStatus(STATUS_MAP.failed);
      AppActions.setLoading(false);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchArtists().then(() => {});
  }, [fetchArtists]);

  return {
    artists,
    total,
    status,
    STATUS_MAP,
  };
};

export default useArtists;
